<script setup lang="ts">

</script>

<template>
    <div class="container">
        <NuxtPage />
    </div>
</template>

<style lang="scss">
@import "~/assets/css/highlight.css";

$primary: #00C599;

html {
  background-color: rgba(#282c34, .9);
}

h2 {
  font-size: 1.5rem;
  color: $primary;
  font-weight: bolder;
  letter-spacing: 0.1rem;
  text-transform: uppercase;
  position: relative;
  display: inline-block;
  padding-bottom: .5rem;

  &::after {
    content: '';
    position: absolute;
    height: 4px;
    left: 1px;
    right: 0;
    bottom: 0;
    background-color: $primary;
    border-radius: 5px 5px 0 0;
  }
}

.container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 2rem;
  font-size: 1rem;
}

pre {
  position: relative;
  padding-left: 4px;

  &::before {
    content: '';
    position: absolute;
    height: calc(100% - 2rem);
    width: 4px;
    left: 0;
    top: -4px;
    background-color: $primary;
    border-radius: 5px 0 0 5px;
  }

  code {
    border-radius: 0 5px 5px 5px;
  }
}
</style>
